import React, { FC, ReactNode } from 'react';

import {
  MetricListArrType,
  MetricObjType,
} from '@app/interfaces/pages-types/anatylics-metric.type';
import { EasyModal } from '@ui';

import HandleMetricForm from './handle-metric-form';

type HandleMetricModalBlockProps = {
  show: boolean;
  onClose: () => void;
  label: ReactNode;
  metricSettings: MetricObjType;
  editModal: boolean;
  metricList?: MetricListArrType[];
};

const HandleMetricModalBlock: FC<HandleMetricModalBlockProps> = (props) => {
  const { show, onClose, label, metricSettings, metricList, editModal } = props;
  return (
    <EasyModal
      show={show}
      label={label}
      customLayer={90}
      isAutoClosable={false}
      withoutFooter
      onClose={onClose}
      variant="mediumW650"
    >
      <div className="h-full w-full z-40 px-[20px]">
        <HandleMetricForm
          metricSettings={metricSettings}
          metricList={metricList || []}
          onClose={onClose}
          isEdit={editModal}
        />
      </div>
    </EasyModal>
  );
};

export default HandleMetricModalBlock;
