import { createApi } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';

import {
  // ChartsListOnDashboard,
  ChartsListOnDashboardResponse,
  CreateChartOnDashboardType,
  DashboardChartSettings,
  dashboardChartsSettings,
  EditGraphLayoutType,
} from '@app/interfaces/dashboards.type';

import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

import { ReportChart } from '@app/interfaces/report.type';

import {
  addNewChartOnDashboard,
  deleteChartFromDashboard,
  editSelectedChartOnDashboard,
  setInitialCharts,
} from '@app/store/reducers/dashboard-charts.slice';

import { baseQuery } from '../baseQuery';

export const dashboardChartsApi = createApi({
  reducerPath: 'dashboardChartsApi',
  tagTypes: ['dashboardCharts'],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getDashboardGraphList: build.query<(dashboardChartsSettings & ReportChart)[], string>({
      async queryFn(arg, queryApi, extraOptions, fetchWithBQ) {
        const graphsArray: (dashboardChartsSettings & ReportChart)[] = [];
        const graphsOnDashboard = (await fetchWithBQ({
          url: serverRoutes.dashboardGraphs,
          params: { dashboard_id: arg },
        })) as unknown as { data: ChartsListOnDashboardResponse };
        const data = graphsOnDashboard?.data?.dashboard_graph_list;
        for (const graph of data) {
          const graphData = (await fetchWithBQ({
            url: serverRoutes.chartData,
            method: 'POST',
            body: { id: graph.live_report_graph_id, ...graph.settings_override },
          })) as unknown as {
            data: { data: dashboardChartsSettings & ReportChart };
            error?: {
              status: number;
              data: {
                data: ReportChart;
              };
            };
          };
          if (graphData.error?.status) {
            const errorChartSet: dashboardChartsSettings & ReportChart = {
              ...graphData.error.data.data,
              type: 'barchart',
              errorStatus: graphData.error?.status,
              graph_id: graph.live_report_graph_id,
              name: 'error',
              dataLabels: false,
              categories: [],
              series: [],
              range: { type: 'l', parameters: [{ num: 7 }] },
              // order_by: OrderByChartSettings;
              line_settings: { active: false, color: '', value: [] },
              styles: {
                header_size: 24,
                text_size: 12,
                value_position: 'inside',
                grid: false,
                text_color: '#000',
                legend_position: 'bottom',
              },
              dashboard_graph_id: graph.dashboard_graph_id,
              live_report_id: graph.live_report_id,
              layout: { ...graph.layout, i: graph.dashboard_graph_id },
            };
            switch (graphData.error?.status) {
              case 500:
                graphsArray.push({
                  ...graphData.error.data.data,
                  errorStatus: graphData.error?.status,
                  dashboard_graph_id: graph.dashboard_graph_id,
                  live_report_id: graph.live_report_id,
                  layout: { ...graph.layout, i: graph.dashboard_graph_id },
                });
                break;
              case 403:
                graphsArray.push(errorChartSet);
                break;
              case 504:
                graphsArray.push(errorChartSet);
                break;
              default:
                break;
            }
          } else {
            graphData.data &&
              graphsArray.push({
                ...graphData.data.data,
                dashboard_graph_id: graph.dashboard_graph_id,
                live_report_id: graph.live_report_id,
                layout: { ...graph.layout, i: graph.dashboard_graph_id },
              });
          }
        }
        return graphsArray
          ? { data: graphsArray, isLoading: true }
          : { error: { error: '123', data: [] } as FetchBaseQueryError };
      },
      providesTags: ['dashboardCharts'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setInitialCharts({ data, type: 'initialSetup' }));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    addNewGraphOnDashboard: build.mutation<
      {
        createdChartOnDashboard: DashboardChartSettings;
        createdChartData: ReportChart;
      },
      CreateChartOnDashboardType
    >({
      async queryFn(arg, _, extraOptions, fetchWithBQ) {
        const createGraphData = (await fetchWithBQ({
          url: serverRoutes.dashboardGraph,
          method: 'POST',
          body: arg,
        })) as unknown as { data: DashboardChartSettings };

        const graphData = (await fetchWithBQ({
          url: serverRoutes.chartData,
          method: 'POST',
          body: { id: createGraphData.data.live_report_graph_id },
        })) as unknown as { data: { data: ReportChart } };

        return createGraphData
          ? {
              data: {
                createdChartOnDashboard: createGraphData.data,
                createdChartData: graphData.data.data,
              },
            }
          : { error: { error: '123', data: [] } as FetchBaseQueryError };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const {
            data: { createdChartOnDashboard, createdChartData },
          } = await queryFulfilled;
          dispatch(addNewChartOnDashboard({ createdChartOnDashboard, createdChartData }));
        } catch (error) {
          dispatch(dashboardChartsApi.util.invalidateTags(['dashboardCharts']));
          console.error(error);
        }
      },
    }),
    editGraphOnDashboard: build.mutation<
      {
        editedChartOnDashboard: DashboardChartSettings;
        editedChartData: ReportChart;
      },
      { body: CreateChartOnDashboardType; id: string }
    >({
      async queryFn(arg, _, extraOptions, fetchWithBQ) {
        const editedGraphData = (await fetchWithBQ({
          url: serverRoutes.dashboardGraph,
          method: 'PUT',
          params: { dashboard_graph_id: arg.id },
          body: arg.body,
        })) as unknown as { data: DashboardChartSettings };

        const graphData = (await fetchWithBQ({
          url: serverRoutes.chartData,
          method: 'POST',
          body: {
            id: editedGraphData.data.live_report_graph_id,
            range: arg.body.settings_override.range,
          },
        })) as unknown as { data: { data: ReportChart } };

        return editedGraphData
          ? {
              data: {
                editedChartOnDashboard: editedGraphData.data,
                editedChartData: graphData.data.data,
              },
            }
          : { error: { error: '123', data: [] } as FetchBaseQueryError };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const {
            data: { editedChartData, editedChartOnDashboard },
          } = await queryFulfilled;
          dispatch(editSelectedChartOnDashboard({ editedChartOnDashboard, editedChartData }));
        } catch (error) {
          dispatch(dashboardChartsApi.util.invalidateTags(['dashboardCharts']));
          console.error(error);
        }
      },
    }),
    deleteGraphOnDashboard: build.mutation({
      query: ({ dashboard_graph_id }: { dashboard_graph_id: string; dashboard_id?: string }) => ({
        url: serverRoutes.dashboardGraph,
        method: 'DELETE',
        params: { dashboard_graph_id },
      }),
      onCacheEntryAdded: async (arg, { dispatch, cacheDataLoaded }) => {
        await cacheDataLoaded;
        dispatch(deleteChartFromDashboard(arg.dashboard_graph_id));
      },
    }),
    // dashboard layout change
    editDashboardGraphLayout: build.mutation<
      DashboardChartSettings,
      { body: EditGraphLayoutType[]; params: { dashboard_id: string } }
    >({
      query: ({ body, params }) => ({
        url: serverRoutes.dashboardLayout,
        method: 'PUT',
        params,
        body: { dashboard_graph_order: body },
      }),
    }),
  }),
});

export const {
  useGetDashboardGraphListQuery,
  useAddNewGraphOnDashboardMutation,
  useEditGraphOnDashboardMutation,
  useDeleteGraphOnDashboardMutation,
  useEditDashboardGraphLayoutMutation,
} = dashboardChartsApi;
