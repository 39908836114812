import { createApi } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';

import {
  NGramCloudStatusType,
  WordsNGramSettingsResponse,
  WordsNGramSettingsUpdateType,
  WordsNGramStaticRequestType,
  WordsNGramStaticResponseType,
} from '@app/interfaces/words-n-gramm.type';

import { baseQuery } from '../baseQuery';
export const wordsNGramApi = createApi({
  reducerPath: 'wordsNGramApi',
  tagTypes: ['wordsNGram', 'wordsNGramSettings', 'cloudStatus'],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getWordsNGramStaticCloud: build.query<
      WordsNGramStaticResponseType,
      WordsNGramStaticRequestType
    >({
      query: (params) => ({
        url: serverRoutes.gramCloudData,
        params: params,
      }),
      providesTags: ['wordsNGram'],
    }),
    getWordsNGramSettings: build.query<WordsNGramSettingsResponse, { live_report_id: string }>({
      query: (params) => ({
        url: serverRoutes.gramCloud,
        params,
      }),
      providesTags: ['wordsNGramSettings'],
    }),
    updateWordsNGramStaticCloudSettings: build.mutation({
      query: ({ params, body }: WordsNGramSettingsUpdateType) => ({
        url: serverRoutes.gramCloud,
        method: 'PUT',
        params,
        body,
      }),
      invalidatesTags: ['wordsNGramSettings'],
    }),
    startCalculateWordNGramCloud: build.mutation({
      query: (params: { live_report_id: string }) => ({
        url: serverRoutes.gramCloudUpdate,
        method: 'PUT',
        params,
      }),
      invalidatesTags: ['cloudStatus'],
    }),
    getCloudStatus: build.query<NGramCloudStatusType, { live_report_id: string }>({
      query: (params) => ({
        url: serverRoutes.gramCloudStatus,
        method: 'GET',
        params,
      }),
      providesTags: ['cloudStatus'],
    }),
  }),
});

export const {
  useGetWordsNGramStaticCloudQuery,
  useUpdateWordsNGramStaticCloudSettingsMutation,
  useStartCalculateWordNGramCloudMutation,
  useGetWordsNGramSettingsQuery,
  useGetCloudStatusQuery,
} = wordsNGramApi;
