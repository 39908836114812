import { Button, CircleButton, EasyModal, Select } from '@app/components/ui';
import { BigChartIcon } from '@app/components/ui/icons/icons-list';
import useChartDateIntervals from '@app/hooks/use-chart-date-intervals';
import useTranslation from '@app/hooks/use-translation';
import { ChartsLayoutType, CreateChartOnDashboardType } from '@app/interfaces/dashboards.type';
import React, { useEffect } from 'react';
import {
  useAddNewGraphOnDashboardMutation,
  useEditGraphOnDashboardMutation,
} from '@app/store/api/dashboard-charts.api';
import { useAppSelector } from '@app/store/store';

type PropsType = {
  show: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  getChartName: string;
  getChartRange: string | undefined;
  live_report_graph_id: string;
  editMode?: boolean;
  dashboard_graph_id?: string;
  width?: string;
  range?: string | number;
  onOpenRemoveModal?: () => void;
};

export const ChartEditModal: React.FC<PropsType> = (props) => {
  const {
    show = false,
    editMode = false,
    onClose,
    getChartName,
    dashboard_graph_id,
    live_report_graph_id,
    range = 'week',
    getChartRange,
  } = props;
  const { userSettings } = useAppSelector((state) => state.userSettings);
  const { charts } = useAppSelector((state) => state.dashboardCharts);
  const [createNewChartOnDashboard] = useAddNewGraphOnDashboardMutation();
  const [editChartOnDashboard] = useEditGraphOnDashboardMutation();
  const [chartRange, setChartRange] = React.useState<string | number>(range);
  const { daysSelectOptions, dateIntervals } = useChartDateIntervals();
  const reportsTimeIntervals = [...daysSelectOptions];
  const { t } = useTranslation('pages.dashboard.modals.editModal');
  const resetFields = () => {
    setChartRange('week');
  };
  const getLayoutSetup = () => {
    const maxAvailableWidth = 24;
    const defaultHeight = 6;
    const defaultWidth = 12;
    if (!charts?.length) {
      return { w: defaultWidth, h: defaultHeight, x: 0, y: 0 };
    }
    const layoutsData = charts?.map((chart) => chart.layout);
    const yMaxIntoLayout = Math.max(...layoutsData.map((layout) => layout.y));
    const xMaxIntoLayout = Math.max(
      ...layoutsData.filter((layout) => layout.y === yMaxIntoLayout).map((layout) => layout.x),
    );
    const layoutFromYMaxInLayout = layoutsData?.find(
      (layout) => layout.x === xMaxIntoLayout && layout.y === yMaxIntoLayout,
    ) as ChartsLayoutType;
    const widthCalculations =
      maxAvailableWidth - (layoutFromYMaxInLayout.x + layoutFromYMaxInLayout.w);
    return {
      w: widthCalculations >= 6 ? (widthCalculations < 0 ? 0 : widthCalculations) : defaultWidth,
      h: defaultHeight,
      x: widthCalculations >= 6 ? layoutFromYMaxInLayout.x + layoutFromYMaxInLayout.w : 0,
      y: widthCalculations >= 6 ? yMaxIntoLayout : yMaxIntoLayout + 1,
    };
  };
  const onButtonClick = () => {
    const dashboard = userSettings?.currentDashboardId;
    const range = dateIntervals[String(chartRange)] || dateIntervals['week'];
    if (!dashboard) return;
    const data: CreateChartOnDashboardType = {
      dashboard_id: dashboard,
      live_report_graph_id: live_report_graph_id,
      settings_override: {
        range,
      },
      layout: getLayoutSetup(),
    };
    if (editMode && dashboard_graph_id && charts) {
      editChartOnDashboard({
        body: {
          ...data,
          layout: charts?.find((chart) => chart.dashboard_graph_id === dashboard_graph_id)?.layout,
        },
        id: dashboard_graph_id,
      });
    } else {
      createNewChartOnDashboard(data);
    }
    onClose(false);
    resetFields();
  };
  const selectData = [...reportsTimeIntervals.splice(0, 6), daysSelectOptions[6]];

  useEffect(() => setChartRange(getChartRange as string), [getChartRange]);
  return (
    <EasyModal
      withoutContentPadding
      withoutLabel
      variant="smallW300"
      withoutFooter
      show={show}
      onClose={() => {
        resetFields();
        onClose(false);
      }}
      customLayer={50}
    >
      <div className="border-b-[1px] px-[-20px] flex w-full gap-[12px] pl-[12px] items-center border-[#E5E8EB] py-[18px]">
        <CircleButton
          onClick={() => {
            resetFields();
            onClose(false);
          }}
          size={14}
          className="text-action"
          icon={'ArrowLeftIcon'}
        />
        <div
          title={getChartName}
          className="max-w-[100px] whitespace-nowrap text-ellipsis overflow-hidden text-1color text-[13px] font-bold"
        >
          {getChartName}
        </div>
        <BigChartIcon size={50} />
      </div>
      <div className="ml-[20px] mt-[17px] min-h-[250px]">
        {/* 2 block */}
        <div className="mt-[25px]">
          <div className="text-[11px] mb-[12px] text-3color">{t('choose_interval')}</div>
          <div className="w-[157px]">
            <Select
              defaultValue={chartRange}
              dropWidth={250}
              size={254}
              onChange={(value) => setChartRange(value)}
              options={selectData}
            />
          </div>
        </div>
        {/* 2 block */}
        <div className="mt-[45px] px-[10px] text-[11px] text-3color leading-[110%] tracking-tight ">
          {t('warning_text')}
        </div>
      </div>
      <div className="py-[25px] px-[20px]">
        <Button
          onClick={() => onButtonClick()}
          className="w-full"
          label={editMode ? t('save_chart_settings') : t('add_chart')}
        />
      </div>
    </EasyModal>
  );
};
