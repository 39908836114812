module.exports = {
  popup_hints: {
    oscillogram_view: 'Выбрать формат отображения осциллограммы', //
    download_record: 'Скачать аудиозапись', //
    record_play: 'Воспроизвести', //
    playing_speed: 'Скорость воспроизведения', //
    download_transcript: 'Скачать транскрипт', //
    copy_link: 'Копировать ссылку',
    show_close: 'Показать/скрыть',
    go_to: 'Перейти к значению',
  },
  is_no_dialog: 'Диалог отсутствует',
  soon: 'Скоро',
  recog_model: 'Модель распознавания:',
  skeleton_loading_label: 'Загрузка аудио...',
  record_not_found: 'Запись не найдена',
  audio: 'Аудиозапись',
  delete: 'Удалить',
  delete_record: 'Удалить запись',
  record_will_deleted: 'Запись будет удалена',
  audoigramm: 'Аудиограмма',
  metric_list: 'Список рассчитанных метрик',
  metric: 'Метрика',
  metric_value: 'Значение',
  meta_data: 'Метаданные',
  client: 'Клиет',
  operator: 'Оператор',
  sec: 'сек',
  dialog: 'Диалог',
  link_copied: 'Ссылка на аудио скопирована!',
  translation_download_false: 'Ошибка загрузки',
  channels_meta_name: 'Имя',
  channels_meta_channel: 'Канал',
  channels_meta_gsm: 'Локация',
  metric_show_empty: 'Отображать пустые',
  end_dialog: 'Конец диалога',
  empty_dialog: 'Запись ещё не распознана',
  emotions: 'эмоции',
  chat_type: 'Сменить вид чата'
};
