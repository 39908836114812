import { CircleButton, EasyModal } from '@ui';
import Icon from '@app/components/ui/icons';
import useTranslation from '@app/hooks/use-translation';
// import classNames from 'classnames';
import React from 'react';

export const HoverButton: React.FC<{ helperText?: string }> = ({ helperText = '' }) => {
  const [showHelper, setShowHelper] = React.useState(false);
  const { t } = useTranslation('pages.metricTemplates');
  return (
    <>
      <CircleButton
        onClick={() => setShowHelper(!showHelper)}
        className="text-defaultColor pb-1"
        icon={'HelpCircleIcon'}
        size={15}
      />
      <div className="">
        <EasyModal
          show={showHelper}
          onClose={() => setShowHelper(false)}
          withoutFooter
          variant="small"
        >
          <div className="w-full p-4">
            <div className="flex flex-col items-center justify-center gap-[20px]">
              <div className="">
                <Icon
                  size={30}
                  className="text-defaultColor p-4 bg-defaultColor bg-opacity-10 rounded-full"
                  name={'HelpCircleIcon'}
                />
              </div>
              <div>
                <h2>{t('help')}</h2>
              </div>
              <div className="block">
                <div className="text-[14px] mx-12 text-wrap leading-normal text-1color font-medium">
                  {helperText}
                </div>
              </div>
            </div>
          </div>
        </EasyModal>
      </div>
    </>
  );
};
