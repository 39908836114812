import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router';

import { PageHeader, Select, Skeleton } from '@ui';
import useTranslation from '@app/hooks/use-translation';
import { OptionItem } from '@app/components/ui/select/select.type';
import { useGetChannelsQuery } from '@app/store/api/get-channels-info.api';

import { useGetAllLiveReportsListQuery } from '@app/store/api/live-reports.api';

import {
  WordNGramStatusesType,
  WordsNGramStaticRequestType,
} from '@app/interfaces/words-n-gramm.type';

import { useGetAllDictionariesListQuery } from '@app/store/api/dictionaries.api';

import {
  useGetCloudStatusQuery,
  useGetWordsNGramSettingsQuery,
} from '@app/store/api/get-words-n-gramm.api';

import { NotFoundedPage } from '@app/pages';

import { WordsCloudFilter } from './blocks/words-cloud-filter';
import { WordCloudContext } from './types/types';
import WordsCloudSkeleton from './blocks/words-cloud.skeleton';

const WordsNGramm = () => {
  const { t } = useTranslation('pages.wordsNGramm');
  const [projectId, changeProjectId] = useState<string | undefined>(
    localStorage.getItem('cloud_n_gram') || undefined,
  );
  const { data: dictionaries, isLoading: dictLoading } = useGetAllDictionariesListQuery();

  const [dataForCloud, setDataForCloud] = useState<WordsNGramStaticRequestType>();
  const [nGramCloudStatus, changeStatus] = useState<WordNGramStatusesType>();
  const { data, isLoading } = useGetChannelsQuery(undefined);
  const { data: projectsList, isLoading: loading } = useGetAllLiveReportsListQuery({ limit: 100 });
  const { data: cloudSettings, isFetching: settingsLoading } = useGetWordsNGramSettingsQuery(
    {
      live_report_id: projectId || '',
    },
    { refetchOnMountOrArgChange: true, skip: projectId === 'undefined' },
  );

  const { data: cloudStatus } = useGetCloudStatusQuery(
    {
      live_report_id: projectId || '',
    },
    {
      refetchOnMountOrArgChange: true,
      pollingInterval: 5000,
      skip:
        projectId !== 'undefined'
          ? nGramCloudStatus === 'EMPTY' || nGramCloudStatus === 'READY'
          : true,
    },
  );
  useEffect(() => {
    changeStatus(cloudStatus?.status);
    if (cloudStatus?.status !== 'READY' || !projectId) return;
    setDataForCloud({ limit: 100, live_report_id: projectId });
  }, [cloudStatus, projectId]);

  useEffect(() => {
    if (projectId) return;
    const project = projectsList?.live_reports?.[0].live_report_id;
    localStorage.setItem('cloud_n_gram', project as string);
    changeProjectId(project);
  }, [projectId, projectsList]);

  function refetchStatus() {
    changeStatus(undefined);
  }

  function handleSelectOnChange(option: string | number) {
    localStorage.setItem('cloud_n_gram', option as string);
    changeProjectId(option as string);
    refetchStatus();
    setDataForCloud(undefined);
  }

  const renderSelectProjectOptions: Array<OptionItem> = projectsList?.live_reports.length
    ? projectsList?.live_reports.map(({ name, live_report_id }) => ({
        title: name,
        value: live_report_id,
      }))
    : [];
  function onFormSubmit(data: Omit<WordsNGramStaticRequestType, 'live_report_id'>) {
    if (!projectId) return;
    setDataForCloud({ ...data, live_report_id: projectId });
  }

  const renderSelectProject = loading ? (
    <Skeleton width={234} height={32} />
  ) : (
    <Select
      size={240}
      onChange={handleSelectOnChange}
      placeholder={t('select_all_projects')}
      options={renderSelectProjectOptions}
      defaultValue={projectId}
      isEmpty
      dropWidth={238}
    />
  );
  if (!projectsList?.live_reports?.length) return <NotFoundedPage text={t('error_page')} />;
  if (loading || isLoading || dictLoading || settingsLoading) return <WordsCloudSkeleton />;
  return (
    <>
      <PageHeader label={t('module_name')} icon="CloudIcon">
        <div className="inline-flex font-[500] text-[15px] text-1color items-center">
          {t('choose_project')}:<div className="ml-[11px]">{renderSelectProject}</div>
        </div>
      </PageHeader>

      {nGramCloudStatus === 'CALCULATING' || nGramCloudStatus === 'NEED_CALCULATE' ? (
        <WordsCloudSkeleton withoutPageHeader label={t('system.cloud_is_calculating')} />
      ) : (
        <div className="relative h-[calc(100%-90px)] w-full">
          <WordsCloudFilter
            projectId={projectId}
            disableSaveButton={!!projectId}
            onFormSubmit={onFormSubmit}
            channels={data}
            dictionaries={dictionaries || []}
            cloudSettings={cloudSettings}
            refetchCloudStatus={refetchStatus}
            cloudStatus={nGramCloudStatus}
            analyticsProjectByReport={
              projectsList?.live_reports.find((report) => report.live_report_id === projectId)
                ?.project
            }
          />
          <div className=" absolute left-0 bottom-0 w-[calc(100%-410px)] h-[calc(100%-90px)]">
            <Outlet
              context={
                {
                  data: dataForCloud,
                  cloudWasCalculated: cloudSettings?.dt_calc,
                } as WordCloudContext
              }
            />
          </div>
        </div>
      )}
    </>
  );
};

export default WordsNGramm;
