module.exports = {
  popup_hints: {
    grid_layout_settings: 'Изменить размер и расположение графиков',
  },
  interval_under_label: {
    from: 'с',
    to: 'по'
  },
  system: {
    none_projectMetric_info: 'В данном аналитическом проекте нет созданных метрик, построение графика невозможно',
    no_metric:'Одна из метрик графика удалена,график не может быть построен',
    no_access:'Нет доступа к данным отчета и графика.',
    no_data:'Нет данных.',
  },
  choose_category: 'Выбор категории',
  status: 'Статус',
  empty: 'В отчете нет графиков',
  new_chart: 'Новый график',
  loading: 'Поиск графиков',
  menu: 'Меню',
  barchart:'Гистограмма',
  horizontal_barchart:'Горизонтальные бары',
  columnchart:'Сегментированная гистограмма',
  piechart:'Круговая',
  linechart:'Линейный график',
  yaxis_min_value: 'Вертикальная ось с минимального значения',
  popover: {
    add_diagram: 'Добавить диаграмму',
    gist: 'Гистограмма',
  },
  drop_menu: {
    intervals: 'Задать временной период для всех диаграмм',
    edit: 'Редактировать',
    remove: 'Удалить',
    download_svg:"Скачать SVG",
    download_csv: "Скачать CSV",
    download_png: "Скачать PNG",
    to_report: 'Перейти в отчет',
    toolbar: 'Панель инструментов',
  },
  order_by_options: {
    asc: 'По возрастанию',
    desc: 'По убыванию',
    none: 'Нет'
  },
  legend_position_options: {
    bottom: 'Внизу',
    left: 'Слева',
    right: 'Справа'
  },
  value_positions_options: {
    inside: 'Внутри',
    outside: 'Снаружи'
  },
  empty_charts: 'У отчета еще нет графиков',
  remove_chart_title: 'Удалить график',
  remove_chart_body: 'Вы точно хотите удалить?',
  select: {
    date_placeholder: 'Выберите дату',
    day: '1 день',
    weekdays: '7 дней',
    monthDays: '30 дней',
    today: 'Текущий день',
    week: 'Текущая неделя',
    month: 'Текущий месяц',
    all_data: 'Все данные',
    custom_date: 'Диапазон',
    aggregate_choices: 'Выбрать',
    metric_choices: 'Выбрать',
    color_choices: 'Изменить',
  },
  labels: {
    customizing_data_to_display_on_a_histogram_y1:
      'Настройка данных для отображения на гистограмме (вертикальная ось Y1)',
    customizing_data_to_display_on_a_histogram_y2:
      'Настройка данных для отображения на гистограмме (вертикальная ось Y2)',
    additional_vertical_axis: 'Дополнительная вертикальная ось',
    metric_axis: 'Выбор метрики',
    metric_axis_y2: 'Выбор метрики (ось Y2)',
    aggregate_data: 'Агрегация данных',
    label_color_col: 'Цвет',
    label_color_line: 'Цвет линии',
  },
  button: {
    remove_chart: 'Удалить график',
    color_edit: 'Изменить',
  },
  interval_error: 'Интервал не указан или неверный интервал',
  modal_remove_chart_title: 'Подтвердите удаление',
  modal_interval_title: 'Задать период для всех графиков',
  modal_interval_success_button: 'Применить',
  modal_interval_before: 'от',
  modal_interval_after: 'до',
  modal_interval_select_placeholder: 'Период: Указать период',
  modal_interval_or: 'или',
  aggregate: {
    mid: 'среднее',
    sum: 'сумма',
    count: 'количество',
    min: 'min значение',
    max: 'max значение',
    prop_sum: 'доля от суммы по столбцу',
    prop_num: 'доля',
  },
  date_select: {
    h: 'по часам',
    d: 'по дням',
    w: 'по неделям',
    m: 'по месяцам',
  },
  add_chart: 'Добавить диаграмму',
  settings: 'Общие настройки',
  axisSettings: 'Настройки осей',
  additionalSettings: 'Дополнительные настройки',
  chart_name: 'Название диаграммы',
  not_filled: 'Поле не заполнено',
  diagram_type: 'Тип диаграммы',
  default: 'Выбор периода по умолчанию',
  display_values: 'Отобразить значения на диаграмме',
  display_legend: 'Отобразить легенду',
  nullable_values: 'Отобразить нулевые значения',
  order_by: 'Сортировка',
  empty_values: 'Отобразить значения “пусто“',
  max_group_display: 'Количество групп значений на графике',
  data_hor_x: 'Выбор данных для группировки (настройка горизонтальной оси X)',
  data_x: 'Данные для группировки (ось X)',
  line_settings: 'Конфигурация линии нормы',
  line_settings_color: 'Цвет',
  line_settings_value: 'Значение',
  styles: 'Дополнительные настройки стилей',
  styles_header_size: 'Размер шрифта названия диаграммы',
  styles_text_size: "Размер шрифта значений",
  styles_text_color: "Цвет шрифта значений",
  styles_value_position: "Расположение значений",
  styles_grid: "Отображение направляющих",
  styles_legend_position: "Расположение легенды",
  data_settings: 'Настройка данных для отображения на графике',
  conditional_formatting_add: 'Добавить настройки условного форматирования',
  conditional_formatting: 'Условное форматирование',
  conditional_formatting_axis: 'Ось',
  conditional_formatting_value: 'Диапазон',
  conditional_formatting_from: 'от',
  conditional_formatting_before: 'до',
  choose: 'Выбрать',
  date_call: 'Дата и время звонка',
  metric: 'Метрика',
  analytic_project: 'Аналитический проект',
  ap: 'АП',
  palettes:'Наборы цветов',
  palette:'Набор цветов',
  chart_toolbar:{
    download: 'Скачать',
    selection: 'Выбрать',
    selectionZoom: 'Увеличить выбранное',
    zoomIn: 'Увеличить',
    zoomOut: 'Уменьшить',
    pan: 'Переместить',
    reset: 'Сбросить настройки',
  }
};
