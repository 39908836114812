import React, { FC } from 'react';
import { TableColumn, TableItem } from '@app/components/rubber-table/table.types';
import useTranslation from '@app/hooks/use-translation';
import { CircleButton } from '@ui';
import { IntegrationAccessKeyObject } from '@app/interfaces/settings.type';
import RubberTable from '@app/components/rubber-table';
type IntegrationTableBlockPropsType = {
  tableData: IntegrationAccessKeyObject[] | undefined;
  onEditIconClick: (id: string) => void;
  onDeleteIconClick: (id: string) => void;
};
export const IntegrationTableBlock: FC<IntegrationTableBlockPropsType> = (props) => {
  const { onEditIconClick, onDeleteIconClick, tableData } = props;
  const { t } = useTranslation('pages.settings');
  const tableIntegrationColumns: Array<TableColumn> = [
    { index: 'name', title: t('integrations.table.name') },
    { index: 'type', title: t('integrations.table.type') },
    { index: 'actions', title: t('integrations.table.actions'), maxWidth: 110, divider: 'left' },
  ];
  const tableIntegrationData = tableData
    ? (tableData.map((item) => ({
        ...item,
        actions: (
          <div className="inline-flex items-center">
            <CircleButton
              onClick={() => onEditIconClick(item.access_key)}
              className="mr-[20px] text-1color"
              hintTitle={t('alias.popup_hints.setting')}
              size={18}
              dataId={`integration-${item.name}-configure`}
              icon="SettingIcon"
            />
            <CircleButton
              onClick={() => onDeleteIconClick(item.access_key)}
              hintTitle={t('alias.popup_hints.delete')}
              className="text-basic_red"
              size={18}
              dataId={`integration-${item.name}-delete`}
              icon="TrashIcon"
            />
          </div>
        ),
        type: item.api ? t('integrations.table.integrationapi') : t('integrations.table.restapi'),
      })) as unknown as TableItem[])
    : [];
  return <RubberTable columns={tableIntegrationColumns} dataSource={tableIntegrationData} />;
};
